import { graphql } from 'gatsby'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import media from 'styled-media-query'

import Button from '../components/Button'
import SEO from '../components/seo'
import { Link } from '../i18n'

const Container = styled.section`
  max-width: 1000px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blackOverlay};
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 40px 80px 40px;
  margin: auto 0;

  h1 {
    text-align: center;
  }

  ${media.lessThan('medium')`
    padding: 30px;
  `}

  ${media.lessThan('medium')`
    h1 {
      font-size: 1.625rem;
    }
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
`

const ManufacturePage = ({
  data: {
    allPrismicZabudowaIndywidualna: {
      edges: [
        {
          node: { data },
        },
      ],
    },
  },
  intl,
}) => (
  <>
    <SEO title={intl.formatMessage({ id: 'manufacture' })} />
    <Container>
      <div dangerouslySetInnerHTML={{ __html: data.content.html }} />
      <ButtonContainer>
        <Link to="/contact">
          <Button>
            <FormattedMessage id="contactUs" />
          </Button>
        </Link>
      </ButtonContainer>
    </Container>
  </>
)

export default injectIntl(ManufacturePage)

export const query = graphql`
  query($locale: String!) {
    allPrismicZabudowaIndywidualna(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            content {
              html
            }
          }
        }
      }
    }
  }
`
